import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { MainComponent } from './main/main.component';
import { HomeComponent } from './home/home.component';
import { PricingComponent } from './pricing/pricing.component';
import { ContactComponent } from './contact/contact.component';
import { FeaturesComponent } from './features/features.component';
import { AboutComponent } from './about/about.component';
import { SearchComponent } from './search/search.component';
import { SupportComponent } from './support/support.component';
import { sidebarWidgetsComponent } from './sidebarWidgets/sidebarWidgets.component';

export const AppRoutes: Routes = [{
  path: '',
  redirectTo: 'home',
  pathMatch: 'full',
}, {
  path: '',
  component: MainComponent,
  children: [
    {
      path: 'home',
      component: HomeComponent
    }, {
      path: 'about',
      component: AboutComponent
    }, {
      path: 'web-apps',
      component: PricingComponent
    }, {
      path: 'contact',
      component: ContactComponent
    }, {
      path: 'mobile-apps',
      component: FeaturesComponent
    }, {
      path: 'desktop-apps',
      component: SearchComponent
    }, {
      path: 'our-tems',
      component: SupportComponent
    },
  ]
}];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(AppRoutes)
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
